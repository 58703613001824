<template>
    <section id="contact" class="py-4 ">
            <div class="container-fluid container-lg px-5">
                <h1 class="title text-white text-shadow-light">Un projet ? Une mission ?</h1>
                <h2 class="title text-white text-shadow-light">On en discute ensemble</h2>

                <div class="row gy-4 mt-2">
                    <div class="col-12 col-md-5 d-flex align-items-center" >
                        <img src="../../assets/img/water_drink.jpg" alt="coffee time" width="100%" class="rounded-3">
                    </div>
                    <div class="col12 col-md-6 offset-md-1">
                        <form method="post" action="./contact_form_treatment.php" id="contact_form">
                            <div class="mb-3 d-flex">
                                <div class="me-3">
                                    <label for="contact_surname" class="text form-label text-white">Prénom</label>
                                    <input type="text" class="form-control" id="contact_surname" name="contact_surname" placeholder="">
                                </div>
                                <div>
                                    <label for="contact_name" class="text form-label text-white">Nom</label>
                                    <input type="text" class="form-control" id="contact_name" name="contact_name" placeholder="">
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="contact_email" class="text form-label text-white">Email</label>
                                <input type="email" class="form-control" id="contact_email" name="contact_email" placeholder="prenom.nom@email.com">
                            </div>
                            <div class="mb-3">
                                <label for="contact_phone" class="text form-label text-white">Téléphone</label>
                                <input type="tel" pattern="[0-9]{10}" class="form-control" id="contact_phone" name="contact_phone" placeholder="ex 0601234567">
                            </div>
                            <div class="mb-3">
                                <label for="contact_msg_content" class="text form-label text-white">Message</label>
                                <textarea class="form-control" id="contact_msg_content" name="contact_msg_content" rows="3"></textarea>
                            </div>
                            
                        <input class="btn btn-primary w-100" type="button" value = "envoyer" onclick="submit_contact_form()">
                        
                        </form>
                        <p class="text text-white">Vous préférez m'envoyer un mail directement ? <a href="mailto:erwan.soria@xoop.fr" class="text-white fw-bold">Ecrivez-moi</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
</template>

<script setup lang="ts">


</script>


<style scoped lang="scss">
    section {
        //background: linear-gradient(to bottom, $light,white); 
    }
</style>
  