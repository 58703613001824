<template>
            <footer class="border-top bg-footer">
            <div class="container-fluid container-lg py-3 px-5">
                <div class="row gy-4">
                    <div class=" col-4 d-flex align-items-center">
                        <img class="logo_small me-3" src="../assets/img/logo_X_light.png">
                        <div class="h-100 w-100 fw-bold text-brand text-white text-shadow-light mb-0 mt-0 d-none" >Erwan Soria</div>
                    </div>
                    <div class="col-4 text-center">
                        <a class="text-white text-shadow-light link-hover" data-bs-toggle="modal" data-bs-target="#modal_mention_legales">Mentions légales</a>
                    </div>
                    <div class="modal" id="modal_mention_legales" tabindex="1">
                        <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title fw-bold">Mentions Légales</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <h5 class="text-uppercase fw-bold">Editeur du Site</h5>
                                <p>SORIA Erwan<br>
                                1 place du 1er Mai 36330 Le Poinçonnet<br>
                                0652501448<br>
                                erwan.soria@xoop.fr</p>

                                <h5 class="text-uppercase fw-bold">Directeur de la publication</h5>
                                <p>SORIA Erwan</p>

                                <h5 class="text-uppercase fw-bold">Hébergement</h5>
                                <p>Hostinger</p>

                                <h5 class="text-uppercase fw-bold">Propriété intellectuelle</h5>
                                <p>Le contenu de ce site web est protégé par les lois sur la propriété intellectuelle. Toute reproduction ou utilisation non autorisée du contenu est strictement interdite.</p>
                                
                                <h5 class="text-uppercase fw-bold">Cookies</h5>
                                <p>Ce site utilise des cookies pour améliorer votre expérience de navigation. Vous pouvez désactiver les cookies dans les paramètres de votre navigateur.</p>
                                
                                <h5 class="text-uppercase fw-bold">Liens externes</h5>
                                <p>Ce site peut contenir des liens vers des sites externes sur lesquels nous n'avons aucun contrôle. Nous déclinons toute responsabilité quant au contenu de ces sites externes.</p>
                                
                                <h5 class="text-uppercase fw-bold">Responsabilité</h5>
                                <p>L'éditeur du site ne peut être tenu responsable des erreurs, omissions ou problèmes techniques pouvant survenir sur le site.</p>
                                
                                <h5 class="text-uppercase fw-bold">Contact</h5>
                                <p>Pour toute question ou préoccupation concernant ces mentions légales, veuillez nous contacter à l'adresse suivante : erwan.soria@xoop.fr</p>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                            </div>
                        </div>
                        </div>
                    </div>
                        
                    <div class=" col-4">
                        <div class="h-100 w-100 text-end" >
                            <a class=" p-1 me-2 pro-media text-white" href="https://www.linkedin.com/in/erwan-soria/" target="_blank">
                                <i class="fa-brands fa-linkedin fa-2x "></i>
                            </a>
                            <a class=" p-1  text-white pro-media" href="https://github.com/goinfroooo/" target="_blank">
                                <i class="fa-brands fa-github fa-2x "></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
</template>

<script setup lang="ts">


</script>


<style scoped lang="scss">

    .logo_small {
        max-width: 2em;
        max-height: 2em;
    }

    .bg-footer {

        background-color: rgb(0, 0, 0,0.5);
    }


</style>
  