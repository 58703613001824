<template>
  <Header :isHome="false"></Header>
  <div class="bg-animated">
    <video
      autoplay
      loop
      muted
      playsinline
      class="background-video"
      @loadeddata="onVideoLoaded"
    >
      <source src="../assets/media/bg_moutain_blue.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <transition name="fade">
      <div v-if="videoLoaded" class="content mt-5 p-0">
        <Presentation></Presentation>
        <Competences></Competences>
        <Portfolio></Portfolio>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </transition>
    
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import Header from './Header.vue';
import Competences from './sections/Competences.vue';
import Portfolio from './sections/Portfolio.vue';
import Presentation from './sections/Presentation.vue';
import Contact from './sections/Contact.vue';
import Footer from './Footer.vue';

const videoLoaded = ref(false);

const onVideoLoaded = () => {
  videoLoaded.value = true;
};
</script>

<style scoped lang="scss">
.bg-animated {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.content {
  position: relative;
  z-index: 1;
  height: 100%; /* Make content fill the entire height */
  overflow-y: auto; /* Enable vertical scrolling */
  //background-color: rgba(255, 255, 255, 0.1); /* Slightly transparent background */
  display: flex;
  flex-direction: column;

}

.content > * {
  flex-shrink: 0;
}

.content > Footer {
  margin-top: auto; /* Push footer to the bottom */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}


Footer{
  position: static;
  bottom: 0;
  width: 100%;
  min-height: 5em;
}


</style>
