<template>
        
    <section id="presentation" class="py-4module-quote reveal parallax-vertical-mm-bt reveal-show">
                <div class="container-fluid container-lg px-5">
                    <div class="card h-100 w-100 bg-section">
                        <div class="card-body">
                            <div class="row gy-4 gy-md-0">
                                
                                <div class="col-12 col-md-7 " >
                                    <h1 class="title text-center  text-dark fw-bold   rounded-3 py-2 px-2" >A propos de moi</h1>
                                    <div class="d-md-none mb-3 d-flex justify-content-center"> <!-- Cette div sera affichée uniquement sur les écrans de taille inférieure à md -->
                                        <img class="rounded-3 " src="../../assets/img/photo_profil_3.jpg" alt="Erwan SORIA" width="100%">
                                    </div>
                                    <p class=  "fs-2 text text-start fs-4 px-2">Ingénieur en mécatronique, j'ai choisis d'orienter mon parcours professionel vers le développement informatique.
                                        J'ai une appétance particulière pour la programmation et les langages web.
                                    </p>
                                    <div class="d-md-none d-lg-block">
                                        <h2 class="py-1 px-2">Expérience</h2>
                                        <p class=  "text text-start px-2">J'ai exercé pendant 2 ans en qualité d'ingénieur software chez Icube-research.
                                            Cela m'a permis d'acquérir de l'expérience dans le milieu industriel, en intervenant sur des taches variées
                                            et en étant responsables des projets de développements qui m'étaient confiés.
                                        </p>
                                        <p class= " text text-start px-2">En parrallèle j'ai continué à me former et à découvrir de nouveau outils
                                            pour élargir ma palette technique et gagner en efficacité.<br>
                                            Aujourd'hui ma plus grande force réside dans ma capacité à apprendre rapidement de nouvelles technologies
                                            et à les utiliser dans mes projets.
                                        </p>
                                    
                                        <div class="d-flex ">
                                            <button class="btn btn-primary me-2" onclick="window.location.href='#competence'">voir mes compétences</button>
                                            <button class="btn btn-primary" onclick="window.location.href='#portfolio'">voir mes réalisations</button>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="col-12 d-none d-md-block col-md-5 text-center">
                                    <img class="img-fluid rounded-3" src="../../assets/img/photo_profil_3.jpg" alt="Erwan SORIA">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 d-none d-md-block d-lg-none">
                                    <h2 class="py-1 px-2">Expérience</h2>
                                    <p class=  "text-start fs-4  px-2">J'ai exercé pendant près de 2 ans en qualité d'ingénieur software chez Icube-research.
                                        Cela m'a permis d'acquérir de l'expérience dans le milieu industriel, en intervenant sur des taches variées
                                        et en étant responsables des projets de développements qui m'étaient confiés.
                                    </p>
                                    <p class="text-start fs-4 px-2">En parrallèle j'ai continué à me former et à découvrir de nouveau outils
                                        pour élargir ma palette technique et gagner en efficacité.<br>
                                        Aujourd'hui ma plus grande force réside dans ma capacité à apprendre rapidement de nouvelles technologies
                                        et à les utiliser dans mes projets.
                                    </p>
                                
                                    <div class="d-flex ">
                                        <button class="btn btn-primary me-2" onclick="window.location.href='#competence'">voir mes compétences</button>
                                        <button class="btn btn-primary" onclick="window.location.href='#portfolio'">voir mes réalisations</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

</template>

<script setup lang="ts">

</script>



<style scoped lang="scss">
    section {
        //background: linear-gradient(to bottom, $contrast-color, $dark); 
    }
    .bg-custom {
        background-color: rgb(255, 255, 255,0.5);
    }



</style>
  
