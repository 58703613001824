<template>

<section id="competence" class="py-5 " data-from="0%" data-to="10%" data-timing="quadOut" >
            <div class="container-fluid container-lg px-5 ">

                <div class="row text-white">
                    <div >
                        <h1 class="title text-white text-shadow-light">Mes compétences</h1>
                        <h2 class="title text-white text-shadow-light">Développement full stack & engineering</h2>
                    </div>
                </div>

                <div class="row gy-4 mt-4">
                    <div class="card h-100 w-100 bg-section">
                        <div class="card-body ">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <h2 class="title card-title text-center mb-3">Mon expertise</h2>
                                    <div class="d-lg-none mb-3"> <!-- Cette div sera affichée uniquement sur les écrans de taille inférieure à md -->
                                        <img class="rounded-3" src="../../assets/img/profil.png" width="100%">
                                    </div>
                                    <p class="text text-start fs-5">Titulaire d'un diplôme d'ingénierie en mécatronique
                                        obtenu à l'ENSIL-ENSCI de Limoges, j'ai choisi de me spécialiser dans le développement informatique.
                                        Je dispose également d'un master en Management et Administration des Entreprise (MAE)
                                        me permettant d'appréhender les aspects financiers et organisationnels d'un projet.
                                    </p>
                                    <p class="text text-start fs-5">Au cours de ma carrière j'ai eu l'opportunité de coder des applications en C++ et python,
                                        pour réaliser de l'acquisition et  du traitement de signaux numériques, 
                                        de la communication avec des bases de données et des automates.
                                        J'ai également développer des IHM en langage web permettant de récupérer des informations en base de données
                                        et de les afficher à l'utilisateur. 
                                    </p>
                                    <p class="text text-start fs-5">
                                        J'ai un profil plutôt polyvalent, ce qui me permet d'intervenir sur
                                        les différents aspects techniques d'un projet, afin d'apporter une réponse globale.
                                    </p>
                                </div>
                                <div class="col-12 col-lg-6 d-none d-lg-flex align-items-center"> <!-- Cette div sera affichée uniquement sur les écrans de taille égale ou supérieure à md -->
                                    <img class="rounded-3" src="../../assets/img/profil.png" width="100%">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row gy-4 mt-4">
                    <div class="card h-100 w-100 bg-section">
                        <div class="card-body ">
                            
                            <div class="row gy-4 mt-4">
                                <div class=" col-12 col-md-4  d-flex align-items-center" >
                                    <img class="rounded-3" src="../../assets/img/design.jpg" width="100%">
                                </div>
                                <div class="col-12 col-md-7 offset-md-1" >
                                    <div class="mb-2 py-2 px-3 rounded-3" style="background-color: rgb(255, 255, 255);">
                                        <div class="row align-items-center ">
                                            <div class="col-5 col-sm-4 col-md-5">
                                                <div class="d-flex align-items-center">
                                                    <i class="fab fa-js fa-2x me-4 text-primary"></i>
                                                    <p class="fw-bold m-0">JS</p>
                                                </div>
                                            </div>
                                            <div class="col ms-3">
                                                <div class="progress">
                                                    <div class="progress-bar bg-primary" style="width: 80%" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="mb-2 py-2 px-3 bg-white rounded-3">
                                        <div class="row align-items-center bg-white rounded-3">
                                            <div class="col-5 col-sm-4 col-md-5">
                                                <div class="d-flex align-items-center">
                                                    <i class="fab fa-php fa-2x me-3 text-primary"></i>
                                                    <p class="fw-bold m-0">PHP</p>
                                                </div>
                                            </div>
                                            <div class="col ms-3">
                                                <div class="progress">
                                                    <div class="progress-bar bg-primary" style="width: 75%; " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="mb-2 py-2 px-3 bg-white rounded-3">
                                        <div class="row align-items-center bg-white rounded-3">
                                            <div class="col-5 col-sm-4 col-md-5">
                                                <div class="d-flex align-items-center">
                                                    <i class="fab fa-html5 fa-2x me-4 text-primary"></i>
                                                    <p class="fw-bold m-0">HTML5</p>
                                                </div>
                                            </div>
                                            <div class="col ms-3">
                                                <div class="progress">
                                                    <div class="progress-bar w-100 bg-primary" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="mb-2 py-2 px-3 bg-white rounded-3">
                                        <div class="row align-items-center bg-white rounded-3">
                                            <div class="col-5 col-sm-4 col-md-5">
                                                <div class="d-flex align-items-center">
                                                    <i class="fab fa-css3-alt fa-2x me-0 text-primary"></i>
                                                    <i class="fab fa-bootstrap fa-2x me-3 text-primary"></i>
                                                    <p class="fw-bold m-0">CSS3/B5</p>
                                                </div>
                                            </div>
                                            <div class="col ms-3">
                                                <div class="progress">
                                                    <div class="progress-bar w-50 bg-primary" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row gy-4 mt-4">
                                <div class="col-12 col-md-7" >
                                    <div class="mb-2 p-2 bg-white rounded-3">
                                        <div class="row align-items-center ">
                                            <div class="col-5 col-sm-4 col-md-5">
                                                <div class="d-flex align-items-center">
                                                    <img src="../../assets/icons/c.svg" width="32" height="32" class="me-2 ms-2 text-primary">
                                                    <p class="fw-bold m-0">C++</p>
                                                </div>
                                            </div>
                                            <div class="col ms-3">
                                                <div class="progress">
                                                    <div class="progress-bar bg-primary" style="width: 80%" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="mb-2 py-2 px-3 bg-white rounded-3">
                                        <div class="row align-items-center bg-white rounded-3">
                                            <div class="col-5 col-sm-4 col-md-5">
                                                <div class="d-flex align-items-center">
                                                    <i class="fab fa-python fa-2x me-3 text-primary"></i>
                                                    <p class="fw-bold m-0">Python</p>
                                                </div>
                                            </div>
                                            <div class="col ms-3">
                                                <div class="progress">
                                                    <div class="progress-bar bg-primary" style="width: 60%; " role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="mb-2 py-2 px-3 bg-white rounded-3">
                                        <div class="row align-items-center bg-white rounded-3">
                                            <div class="col-5 col-sm-4 col-md-5">
                                                <div class="d-flex align-items-center">
                                                    <i class="fab fa-vuejs fa-2x me-3 text-primary"></i>
                                                    <p class="fw-bold m-0">VueJS</p>
                                                </div>
                                            </div>
                                            <div class="col ms-3">
                                                <div class="progress">
                                                    <div class="progress-bar w-25 bg-primary"  role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="mb-2 py-2 px-3 bg-white rounded-3">
                                        <div class="row align-items-center bg-white rounded-3">
                                            <div class="col-5 col-sm-4 col-md-5">
                                                <div class="d-flex align-items-center">
                                                    <img src="../../assets/icons/mysql-logo.svg" width="32" height="32" class="me-3 text-primary"></img>
                                                    <p class="fw-bold m-0">Database</p>
                                                </div>
                                            </div>
                                            <div class="col ms-3">
                                                <div class="progress">
                                                    <div class="progress-bar bg-primary" style="width: 80%;" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="col 12 col-md-4 offset-md-1 d-flex align-items-center" >
                                    <img class="rounded-3" src="../../assets/img/code.jpg" width="100%">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

</template>

<script setup lang="ts">


</script>


<style scoped lang="scss">

    section{
        //background: linear-gradient(to bottom, $dark, rgb(11, 0, 112),$dark); 
    }

</style>
  