<template>

<section id="portfolio" class="py-4">
            <div class="container-fluid container-lg px-5">

                <div class="row text-white">
                        <h1 class="title text-white text-shadow-light">Portfolio</h1>
                        <h2 class="title text-white text-shadow-light">Mes projets pour les prochains mois</h2>
                </div>
                <div class="row  gy-4 mt-4">
                    <Project v-for="(project, index) in projects" :key="index" :project="project" />
                </div>
            </div>
        </section>

</template>

<script setup lang="ts">

    import { ref,onMounted } from 'vue';
    import { ProjectInterface } from '../../scripts/interface';
    import Project from './subComponents/Project.vue';
    import img_ecommerce_1 from '@/assets/img/ecommerce1.jpg';
    import img_ecommerce_2 from '@/assets/img/ecommerce2.jpg';
    import img_ecommerce_3 from '@/assets/img/ecommerce3.jpg';
    import img_cornerchess_1 from '@/assets/img/chess1.jpg';
    import img_cornerchess_2 from '@/assets/img/chess2.jpg';
    import img_cornerchess_3 from '@/assets/img/chess3.jpg';
    import img_forum_1 from '@/assets/img/forum1.jpg';
    import img_forum_2 from '@/assets/img/forum2.jpg';
    import img_forum_3 from '@/assets/img/forum3.jpg';
    import img_resume_1 from '@/assets/img/resume1.jpg';
    import img_resume_2 from '@/assets/img/resume2.jpg';

    

    const projects = ref<ProjectInterface[]>([]);

    onMounted(() => {
        const project1: ProjectInterface = {
            title: "Site e-commerce",
            descriptions: [
            "Un site ecommerce simple, mais qui pourra être personnalisé suivant les demandes !",
            "N'hésitez pas à me contacter <a href='#contact'>ici</a> pour que je fasse le vôtre"
            ],
            imgs: [
            img_ecommerce_1,img_ecommerce_2,img_ecommerce_3,
            ],
            url: "https://ecommerce.xoop.website/"
        };
        projects.value.push(project1);

        const project2: ProjectInterface = {
            title: "Corner chess",
            descriptions: [
            "Vous connaissez le jeu d'échecs ? venez découvrir le corner-chess !",
            "Le projet est en version alpha. Des amélioration verront le jour prochainement !"
            ],
            imgs: [
            img_cornerchess_1,img_cornerchess_2,img_cornerchess_3,
            ],
            url: "https://cornerchess.xoop.website"
        };
        projects.value.push(project2);

        const project3: ProjectInterface = {
            title: "Un forum en ligne",
            descriptions: [
            "J'hésite encore entre plusieurs thématiques",
            "Peut-être un site sur l'événementiel ?",
            ],
            imgs: [
            img_forum_1,img_forum_2,img_forum_3,
            ],
            url: "https://xoop.website/wainting_project"
        };
        projects.value.push(project3);

        const project4: ProjectInterface = {
            title: "Le projet 4",
            descriptions: [
            "Je ne sais pas encore ce que ce sera.",
            "En attendant, c'est un ancien CV codé en HTML/CSS.",
            ],
            imgs: [
            img_resume_1,img_resume_2,
            ],
            url: "https://xoop.website/wainting_project"
        };
        projects.value.push(project4);
    
    
    });


</script>


<style scoped lang="scss">
    section {
        //background: linear-gradient(to bottom, $dark, $light); 
    }
</style>
  