<template>
    
    <header class="py-4 mb-5" height ="50">
        <nav class="navbar navbar-expand-md fixed-top navbar-light bg-dark bg-opacity-10" style="max-height: 5em;">
            <div class="container">
                <router-link class="navbar-brand d-flex align-items-center" to="/">
                    <img src="../assets/icons/logo_animated_5_1loop.gif" class="logo_small me-2" >
                        
                    
                    <h1 class=" fw-bold text-light mb-0 d-none">Erwan Soria</h1>
                </router-link>
                
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                
                <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul v-if="props.isHome" class="navbar-nav ">
                        <li class="nav-item active">
                            <router-link class="nav-link text-light text-shadow" :to="{ path: '/Body', hash: '#presentation' }">A propos</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link text-light text-shadow" :to="{ path: '/Body', hash: '#competence' }">Experience</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link text-light text-shadow" :to="{ path: '/Body', hash: '#portfolio' }">Portfolio</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link text-light text-shadow" :to="{ path: '/Body', hash: '#contact' }">Contact</router-link>
                        </li>
                    </ul>
                    <ul v-else class="navbar-nav ">
                        <li class="nav-item active">
                            <a class="nav-link text-light text-shadow" href="#presentation">A propos</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-light text-shadow" href="#competence">Experience</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-light text-shadow" href="#portfolio">Portfolio</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-light text-shadow" href="#contact">Contact</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        

    </header>
    
</template>

<script setup lang="ts">


const props = defineProps({
  isHome: Boolean,
});



</script>



<style scoped lang="scss">
    .logo_small {

        max-width: 2em;
        max-height: 2em;
    }

    .text-shadow {
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }

    @media (max-width: 767.98px) {
        .navbar-nav {
            text-align: left;
            display: inline-block;
            border-radius: 5%;
            background-color: $dark;
            padding: 5px;
            
        }

        .navbar-collapse {
            text-align:right;
        }
    }

    


        
</style>
  
