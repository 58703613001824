import { createRouter, createWebHistory } from 'vue-router'
import Home from './components/Home.vue';
import Body from './components/Body.vue';



const routes = [
  {path: '/',name: 'Home',component: Home},
  {path: '/Body',name: 'Body',component: Body},
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
  },
  routes,
})

export default router
