<template>
  <div class="bg-animated">
    <video @loadeddata="onVideoLoaded()" autoplay loop muted playsinline class="background-video">
      <source src="../assets/media/bg_moon_blue.mp4" type="video/mp4">
      
      Your browser does not support the video tag.
    </video>
    <div v-if="videoLoaded" class="content m-0 p-0">
      <Header :isHome="true"></Header>
        <nav class="navbar navbar-expand-md fixed-top navbar-light">
          <div class="navbar-brand d-none d-md-flex align-items-center" >
            <img src="../assets/icons/logo_animated_8.gif" class="me-2 main-icon">
          </div>
        </nav>
      
      <div class="main-content m-0">
        <div class="container-fluid mt-3 mt-md-5">
          <div class="row title-row mt-0 mt-xl-5">
            <div class="col-md-7 offset-md-5 ">
              <Transition name="title" @after-enter="handleAfterEnter">
                <h1 v-show="showTitle" class="text-white main-title text-shadow ">Salut, moi c'est <span class="text-nowrap">Erwan !</span> </h1> 
              </Transition>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-7 offset-md-5">
                <div :class="{ 'faded': isFaded }"  class="shadow-lg rounded-3 p-0 m-0 p-2 bg-content">
                  <TransitionGroup name="fade" >
                    <p v-show="showParagraphs[0]" key="1" class="text-white text-start fs-4 rounded-3 m-2 p-1 text-shadow-light paragraph " >Passionné par le développement web en général, j'adore apprendre de nouvelles choses dans le monde de la programmation en général</p>
                    <p v-show="showParagraphs[1]" key="2" class="text-white text-start fs-4 rounded-3 p-1 m-2 text-shadow-light paragraph " style="top:174.5px" >De la création d'UI en passant par de la gestion de base de données, de la POO je maitrise de nombreux aspects du développement et brille particulièrement dans des projets pluridisciplinaire.</p>
                    <p v-show="showParagraphs[2]" key="3" class="text-start fs-3 rounded-3  p-1 m-2 paragraph "  >
                      <router-link class="nav-link text-white text-shadow-light fw-bold " to="/Body">Intéressés ? Venez en apprendre plus sur moi !<i class="bi bi-linkedin text-primary"></i></router-link>
                    </p>
                  </TransitionGroup>
                </div>
              
            </div>
          </div>


        </div>
      </div>
    </div>
    <footer >
      <div class="container-fluid w-100 h-100">
        <div class="row h-100">
          <div class="col-12 col-md-4 offset-md-8 text-end">
            <a class=" p-1 me-2 pro-media text-white" href="https://www.linkedin.com/in/erwan-soria/" target="_blank">
              <i class="fa-brands fa-linkedin fa-2x fa-beat"></i>
            </a>
            <a class=" p-1 me-3 text-white pro-media" href="https://www.linkedin.com/in/erwan-soria/" target="_blank">
              <i class="fa-brands fa-github fa-2x fa-beat"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import Header from './Header.vue';

  const videoLoaded = ref<boolean>(false);
  const showParagraphs = ref<boolean[]>([false, false, false]);
  const showTitle = ref<boolean>(false);
    const isFaded = ref(false);

  const onVideoLoaded =  () => {

    videoLoaded.value=true
    setTimeout(() => showTitle.value = true, 500);
      
    setTimeout(() => showParagraphs.value[0] = true, 1500);
    setTimeout(() => showParagraphs.value[1] = true, 2300);
    setTimeout(() => showParagraphs.value[2] = true, 3100);
    //setTimeout(() => test(), 5000);
  };

  const handleAfterEnter = () => {
    isFaded.value = !isFaded.value;

  }

</script>

<style scoped lang="scss">
.main-icon {
  opacity: 0.8;
}

@media (max-width: 992px) {
  .main-icon {
    width: 75%; /* Redimensionne l'image à 75% de sa largeur */
    height: 75%;
    margin-top: 10%
  }
}

.navbar {
  z-index: -1;
}

.bg-animated {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-video {
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  flex-direction: column;
}



.main-content {
  margin-top: 100px; /* Adjust as necessary to position the content below the header */
  flex: 1;
  display: flex;
  align-items: top;
  justify-content: center;
  z-index: 3;
}

footer {
  position: fixed;
  bottom: 0;
  height: 3em;
  width: 100%;
  z-index: 4;
}

.pro-media {
  opacity: 0.6;
}

//Transitions paragraphe
.fade-enter-active {
  transition: all 0.5s ease-out;
}
.fade-leave-active{
  transition: all 0.2s ease-out;
}

.fade-enter-from {
  transform: translateX(-50px);
  opacity: 0;
}

.fade-leave-to {
  transform: translateX(50px);
  opacity: 0;
}

.relative-container {
  position: relative;
  height: 17em; 
}



//transition title

.main-title {
  font-size: 5em;
  text-align: left;
  }


@media (max-width: 768px) {
  .main-title {
  font-size: 3em;
  text-align: center;
  
  }
}



.title-enter-active, .title-leave-active{
  transition: all 0.5s ease-out;
}

.title-enter-from {
  transform: translateY(-50px);
  opacity: 0;
}

.title-leave-to {
  transform: translateX(50px);
  opacity: 0;
}

.paragraph{

  background-color: rgb(0, 0, 0, 0.25);
}

//TTTTTTTTTTTTTTTTTTTTTTTTEEEEEEEEEESSSSSSSSSSSTTTTTTTTTTTTT

.bg-content{
  opacity: 0;
  transition: all 0.5s ease-in-out; /* Transition progressive de l'opacité */
  transform: translateX(50px);
}

@media (max-width: 992px) {
  .main-icon {
    width: 75%; /* Redimensionne l'image à 75% de sa largeur */
    height: 75%;
    margin-top: 10%
  }
}

.faded {
  opacity: 1; /* Opacité lorsque la classe 'faded' est appliquée */
  transform: translateX(0px);
}
</style>
