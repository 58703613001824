<template>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
        <div class="h-100 w-100 " >
            <div class="card h-100 w-100  rounded-3" >
                <div class="card-img-top">
                    <div id="carouselExampleInterval" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div v-for="img in props.project.imgs" class="carousel-item active" :data-bs-interval="getRandomInterval()">
                                <img :src=img class="d-block w-100" alt="...">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body bg-light">
                    <h5 class="title card-title">{{props.project.title}}</h5>
                    <p v-for="description in props.project.descriptions" class="text card-text text-start" v-html="description"></p>
                    
                </div>
                <div class="card-footer bg-light-2 text-center">
                    <a :href=props.project.url class="btn btn-primary">Voir le projet</a>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup lang="ts">
    import { defineProps } from 'vue';
    import { ProjectInterface } from '../../../scripts/interface';

    // Définir les props explicitement avec defineProps
    const props = defineProps<{project: ProjectInterface;}>();

    const getRandomInterval = () => {
    const baseInterval = 2000; // 2000 ms
    const variation = baseInterval*0.5; // 50% of 2000 is 1000
    return baseInterval + (Math.random() * variation * 2 - variation); // base ± variation
};

</script>


<style scoped lang="scss">

.bg-section{
    background-color: rgb(255, 255, 255,0.5);
}
</style>
